import {Component, computed, inject, OnDestroy, signal, WritableSignal} from '@angular/core';
import {BasePopover} from '../base-popover';
import {Survey123Feature} from '../../../../classes/survey123-feature';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {LayerService} from '../../services/layer.service';
import {switchMap} from 'rxjs';
import {MatCardModule} from '@angular/material/card';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {SharedModule} from '../../../../../shared/shared.module';

@Component({
    standalone: true,
    selector: 'eaglei-survey123-popover',
    templateUrl: './survey123-popover.component.html',
    styleUrls: ['./survey123-popover.component.scss'],
    imports: [MatCardModule, AsyncPipe, MatButtonModule, MatIconModule, MatDividerModule, NgIf, MatTabsModule, SharedModule],
})
export class Survey123PopoverComponent extends BasePopover<Survey123Feature> implements OnDestroy {
    public loading = signal(true);
    public imageIds: WritableSignal<number[]> = signal([]);

    public showArrows = computed(() => {
        return this.imageIds().length > 1;
    });

    private layerService = inject(LayerService);
    public currentIndex: number = 0;

    constructor() {
        super();
        this.data$
            .pipe(
                takeUntilDestroyed(),
                switchMap((data) => {
                    const url = `api/survey123/${data.objectid}/imageIds`;
                    return this.layerService.getJsonFromUrl(url);
                })
            )
            .subscribe((imageIds) => {
                this.imageIds.set(imageIds);
                this.currentIndex = 0;
            });
    }

    // Abstract from the super class
    afterInit() {}

    // Abstract from the super class
    onInit() {}

    // Abstract from the super class, We should look at deprecating in favor of using data$
    updateData() {}

    previousPhoto() {
        this.loading.set(true);
        this.currentIndex -= 1;
    }

    nextPhoto() {
        this.loading.set(true);
        this.currentIndex += 1;
    }
}
