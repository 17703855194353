import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {ApplicationConfig} from '../../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import * as moment from 'moment';
import {MatDialogRef} from '@angular/material/dialog';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {ModalConfig} from '../../../../classes/modal-config';
import {AppEnvironment} from '../../../../enums/environment.enum';

@Component({
    selector: 'eaglei-summary-update-modal',
    templateUrl: './summary-update-modal.component.html',
    styleUrls: ['./summary-update-modal.component.scss'],
})
export class SummaryUpdateModalComponent implements AfterViewInit {
    @ViewChild('content') content: ElementRef;

    protected isTraining = (window as any).eaglei.env === AppEnvironment.TRAINING;

    public startDate = moment().subtract(1, 'day');
    public endDate = moment();
    public readonly minDate = this.isTraining ? moment('2014-11-20') : moment().subtract(1, 'month');

    constructor(public ref: MatDialogRef<SummaryUpdateModalComponent>) {}

    ngAfterViewInit() {
        this.setModalHeight();
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(this.setModalHeight);
    }

    private setModalHeight() {
        (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    }

    public updateSummaryData(): void {
        const ret = new CardFilters();
        ret.startDate = this.startDate;
        ret.endDate = this.endDate;
        this.ref.close(ret);
    }

    public updateDates(event: CardFilters) {
        this.startDate = event.startDate;
        this.endDate = event.endDate;
    }
}
