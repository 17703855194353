import {Component, inject} from '@angular/core';
import {EagleILayer} from '../../classes/eagle-i-layer';
import {LayerService} from '../../services/layer.service';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {map, tap} from 'rxjs/operators';
import {Survey123Feature} from '../../../../classes/survey123-feature';
import {FeatureCollection} from '@turf/turf';
import {Survey123Source} from '../../sources/survey123-source';
import {CustomPopover} from '../../enum/custom-popover';
import {GenLegendType} from '../../../../../../generated/serverModels/GenLegendType';
import {FormControl} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ApplicationConfig} from '../../../../classes/application-config';
import * as moment from 'moment';

@Component({
    selector: 'eaglei-survey123-layer',
    templateUrl: './survey123-layer.component.html',
    styleUrls: ['./survey123-layer.component.scss'],
})
export class Survey123LayerComponent extends EagleILayer<Survey123Source> {
    private layerService = inject(LayerService);
    public yearControl = new FormControl<number>(ApplicationConfig.roundMinute().year(), {nonNullable: true});

    constructor() {
        super();

        this.yearControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((dataYear) => {
            this.getSurveyPoints(dataYear);
        });
    }

    /**
     * Implementation of abstract method from the parent class, This method is triggered within the NgAfterViewInit
     * lifecycle hook.
     */
    afterViewInit(): void {
        this.mapLayer.customPopoverId = CustomPopover.SURVEY_123;
        this.mapLayer.legend.type = GenLegendType.HARD_CODED;
    }

    /**
     * Implementation of abstract method from the parent class, this method is fired the view when the state of the
     * slide-toggle is changed
     * @param activeState The checked state of the slide-toggle. True to show the layer, false to hide
     */
    toggleLayer(activeState: boolean): void {
        if (activeState) {
            if (!this.layerSource) {
                this.layerSource = new Survey123Source(this.mapLayer);
            }
            this.yearControl.updateValueAndValidity();
        } else {
            this.layerSource.removeFromMap();
        }
    }

    /**
     * Makes an api call to get all the photos associated with the event corresponding to the passed in id
     * @param surveyYear The data year to use for the survey points
     * @private
     */
    private getSurveyPoints(surveyYear: number): void {
        const startDate = moment().year(surveyYear).startOf('year');
        const endDate = moment().year(surveyYear).endOf('year');
        const url = `${this.mapLayer.serviceurl}?start=${startDate.format()}&end=${endDate.format()}`;

        HttpInterceptorService.clearInterceptor(this.mapLayer.uiHandle);
        HttpInterceptorService.pendingRequests[this.mapLayer.uiHandle] = this.layerService
            .getJsonFromUrl(url)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.mapLayer.uiHandle);
                    this.mapLayer.endLoading();
                }),
                map((fc: FeatureCollection) =>
                    fc.features.map((feature) => {
                        feature.properties = new Survey123Feature(feature.properties);
                        return feature;
                    })
                )
            )
            .subscribe((features) => {
                this.layerSource.processFeatures(features);
                this.layerSource.addToMap(true);
            });
    }
}
